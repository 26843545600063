import React from "react"
import "./IconBox.scss"

function IconBox({ icon }) {
    return (
        <div className="IconBox">
            <i className={`fa-solid fa-${icon}`} />
        </div>
    )
}
export default IconBox