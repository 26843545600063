import React from "react"
import "./TextPage.scss"

function TextPage({ title, children, className = "" }) {
    return (
        <div className={"Page TextPage " + className}>
            <div className="container">
                <h1>{title}</h1>
                <div className="contant">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default TextPage