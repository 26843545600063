import React from "react"
import StaticImage from "../StaticImage/StaticImage"
import "./Logo.scss"

function Logo({ old }) {
    let uri = "/logos/logo_white.png"
    if (old) {
        uri = "/logos/logo_main.png"
    }

    return (
        <div className="Logo">
            <StaticImage uri={uri} alt="Logo" />
        </div>
    )
}
export default Logo 