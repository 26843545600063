import React from "react"
import "./MiniBar.scss"

function MiniBar({ }) {
    return (
        <div className="MiniBar">

        </div>
    )
}
export default MiniBar