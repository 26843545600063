import React from "react"
import MiniBar from "../../../atoms/MiniBar/MiniBar"
import "./TeamBox.scss"

function TeamBox({ }) {
    return (
        <div className="TeamBox mb-5">
            <div className="container">

                <h2>Wir freuen uns auf Sie!</h2>
                <MiniBar />

                <div className="d-flex flex-row flex-wrap ">
                    {members.map((i, k) => <TeamMember key={k} {...i} />)}
                </div>
            </div>
        </div>
    )
}
export default TeamBox

function TeamMember({ title, position, image, url }) {
    return (
        <a href={url} target="_blank" className="TeamMember col-12 col-md-6 col-lg-4 p-0 ">
            <div className="inner">
                <div className="d-flex flex-row align-items-center">
                    <img src={image} className="me-2" />
                    <div>
                        <h3>{title}</h3>
                        <p>{position}</p>
                    </div>
                </div>
            </div>
        </a>
    )
}

let prefix = "/assets/image/team/"
let members = [
    {
        title: "Dr. Eckard Weisel",
        position: "Senior Consultant",
        image: prefix + "dr_eckard_weisel.png",
        url: "mailto:info@k4k-eg.de",
    },
    {
        title: "Maximilian Großmann",
        position: "Vorstand",
        image: prefix + "maximilian_grossmann.png",
        url: "mailto:info@k4k-eg.de",
    },
]