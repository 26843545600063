import "./PowerdBy.scss"

function PowerdBy({ }) {
    return (
        <a href="https://hessenapp.de/" className="PowerdBy" target="_blank">

            <span>Webdesign mit </span>
            <div className="heartOut">
                <div className="heart" />
            </div>
            <span>von</span>
            <img alt="dashdesign; / Hessen App GmbH" src="https://hessenapp.de/wp-content/uploads/2023/07/hessenapp_gmbh_icon-%E2%80%93-4-2048x450.png" />
        </a>
    )
}
export default PowerdBy