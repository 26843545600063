import React from "react"
import { Link } from "react-router-dom"
import EasyBox from "../../components/molecules/boxes/EasyBox/EasyBox"
import EasyBoxFour from "../../components/molecules/boxes/EasyBoxFour/EasyBoxFour"
import ImageBox from "../../components/organisms/ImageBox/ImageBox"
import "./HomePage.scss"

function HomePage({ }) {

    let contactButtons = <>
        <Link to="/kontakt" className="me-2">
            <button className="main">
                Kontakt aufnehmen
                <i className="fa-solid fa-chevron-right" />
            </button>
        </Link>
    </>
    {/* <a target="_blank" className="me-2" href="https://calendly.com/dashdesign-eu/austausch-1h?embed_type=PopupWidget&embed_domain=1">
        <button className="main">
            Termin buchen
            <i className="fa-solid fa-chevron-right" />
        </button>
        </a> */}


    return (
        <div className="Page HomePage">

            <ImageBox uri={"/company/expertenArbeitenAnSoftware.jpg"} title={"Smarte Kommunen \ngemeinsam gestalten."} />


            <EasyBox
                image="/assets/image/company/smarphoneMockup.png"
                title="Stark durch Kooperation"
                subTitle="
                Unsere Philosophie setzt den Fokus auf die Kraft der Gemeinschaft
                und die Entwicklung von Synergien im kommunalen Ökosystem."
                items={[
                    {
                        title: "Produktive Gemeinschaften",
                        desc: "Gleiche Bedürfnisse, Herausforderungen und Themen: Wir schaffen den Rahmen für interkommunale Projekte und geteilte Ressourcen.",
                        icon: "globe",
                    },
                    {
                        title: "Erfolge und Best Practices",
                        desc: "Voneinander lernen, Erfolge und Best Practices teilen: Wir machen die Erfolge unserer Mitglieder sichtbar und fördern kollegiales Lernen.",
                        icon: "thumbs-up",
                    },
                    {
                        title: "Kommunales Netzwerk",
                        desc: "Kommunale Akteure‚ grenzenlos verbunden: Wir vernetzen unsere Mitglieder und Projekte horizontal wie vertikal über das gesamte kommunale System.",
                        icon: "chart-network",
                    },
                ]}
                suffix={contactButtons}
            />



            <ImageBox uri={"/company/kommunenArbeitenZusammen.jpg"} title={"Interkommunal\nneu definiert."} />



            <EasyBox
                odd
                image="/assets/image/company/laptopK4K.png"
                title="Smarte Kollaboration"
                subTitle="Als Inhouse-Gemeinschaft schaffen wir den Rahmen für die interkommunale Zusammenarbeit der Zukunft."
                items={[
                    {
                        title: "Gemeinsam umsetzen",
                        desc: "Gleiches wollen, gemeinsam umsetzen: Wir setzen auf interkommunale Kooperationsprojekte, statt individuelle Leuchttürme.",
                        icon: "handshake-angle",
                    },
                    {
                        title: "Produkte nachnutzen",
                        desc: "Innovation beschleunigen: Wir sorgen für einen vereinfachten Zugriff auf bereits vorhandene Produkte aus dem Mitgliederkreis.",
                        icon: "rectangle-history-circle-user",
                    },
                    {
                        title: "Wissen und Expertise teilen",
                        desc: "Praxiswissen für bedarfsorientierte Lösungen: Wir bauen auf kollegiales Expertenwissen für passgenaue und objektive Entscheidungen.",
                        icon: "lightbulb-on",
                    },
                ]}
                suffix={contactButtons}
            />


            <EasyBoxFour
                colored
                image="/assets/image/company/TabletMitVorteilenFürGemeinden.png"
                title="Von und für Kommunen"
                subTitle="Die K4K eG ist zu 100 Prozent kommunal und eine mitgliedergeführte, solidarische Organisation. Deutschlandweit, für die Zukunft der kommunalen Familie."
                items={[
                    {
                        title: "Landkreise",
                        icon: "map-location",
                    },
                    {
                        title: "Städte und Gemeinden",
                        icon: "building-columns",
                    },
                    {
                        title: "Kommunale Jobcenter",
                        icon: "briefcase",
                    },
                    {
                        title: "Kommunale Unternehmen",
                        icon: "building",
                    },
                ]}
                suffix={contactButtons}

            />


        </div>
    )
}
export default HomePage