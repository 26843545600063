import { isDarkModeActive } from "../../../../functions/theme";
import "./StaticImage.scss";

function StaticImage({
  uri,
  uriDarkMode,
  urlDarkMode,
  url,
  alt,
  className,
  asBackground,
  width,
  height,
  children,
  ariaLabel,
}) {
  let src = url ? url : "/assets/image/" + uri;

  if (isDarkModeActive() && (uriDarkMode || urlDarkMode)) {
    src = urlDarkMode ? urlDarkMode : "/assets/image/" + (uriDarkMode || uri);
  }

  return (
    <div className={"StaticImage " + className}>
      {asBackground ? (
        <div
          aria-label={ariaLabel || alt}
          className="asBackground"
          style={{
            backgroundImage: `url(${src})`,
            height: height,
            width: width,
          }}
        >
          {children}
        </div>
      ) : (
        <img
          alt={alt || "Bild"}
          aria-label={ariaLabel || alt}
          src={src}
          className="object-contain"
        />
      )}
    </div>
  );
}
export default StaticImage;
