import React from "react"
import MiniBar from "../../../atoms/MiniBar/MiniBar"
import IconBox from "../../../atoms/images/IconBox/IconBox"
import "./EasyBoxFour.scss"

let fallbackItems = [
    {
        title: "Effizienzsteigung",
        desc: "Die K4K ermöglicht Gemeinden die Bündelung von Ressourcen, um gemeinsam Innovations- und Effizienzpotenziale durch Digitalisierung auszuschöpfen.",
        icon: "chart-line-up"
    },
    {
        title: "Finanzielle Entlastung",
        desc: "In Zeiten finanzieller Herausforderungen bietet die K4K eine kosteneffiziente Lösung, um notwendige Ressourcen für die Digitalisierung bereitzustellen.",
        icon: "badge-percent"
    },
    {
        title: "Expertennetzwerk & Maßgeschneiderte Lösungen",
        desc: "Durch die Zusammenarbeit mit erfahrenen Experten profitieren K4K-Mitglieder von einem fundierten Wissenspool, der die Anforderungen jeder einzelnen Kommune optimal berücksichtigt.",
        icon: "users-line"
    },
]

function EasyBoxFour({
    title = "Gemeinsam stark in der digitalen Revolution",
    subTitle = "Die K4K eG ermöglicht Kommunen den Schritt in eine vernetzte Zukunft. Entdecken Sie die Vorteile unserer Genossenschaft!",
    items = fallbackItems,
    odd = false,
    colored = false,
    image = "",
    imageAlt = "Symbolbild",
    prefix = <></>,
    suffix = <></>
}) {
    return (
        <div className={"EasyBoxFour pt-5 pb-5 " + (odd && " odd ") + (colored && " colored ")}>
            <div className="container pt-5 pb-5">
                <div className={"row align-items-center"}>
                    <div className="col-12 col-lg-6 pt-4 mb-5 mb-lg-0">
                        {prefix}
                        <h2>{title}</h2>
                        <MiniBar />
                        <p>{subTitle}</p>
                        <div className="row pt-4 pb-4">
                            {items.map((i, k) => <EasyBoxItem key={k} {...i} />
                            )}
                        </div>
                        <div className="d-flex flex-row">
                            {suffix}
                        </div>
                    </div>
                    <div className={"col-12 col-lg-6 "}>
                        <div className="imgBox p-0 p-lg-5">
                            <img className="w-100" src={image} alt={imageAlt} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EasyBoxFour

function EasyBoxItem({ title, desc, icon = "frog" }) {

    return <div className="EasyBoxItem col-12 col-sm-6 mb-3">
        <IconBox icon={icon} />
        <div>
            <h3>{title}</h3>
            <p>{desc}</p>
        </div>
    </div>
}