import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ContactFormular.scss";

const endpoint = "https://formular-service-api.k4k-eg.de/formular";

function ContactFormular({ fields }) {
    const [values, setValues] = useState({});
    const [error, setError] = useState(false);
    const [sended, setSended] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleChange(key, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));
    }

    function handleSend() {
        setError(false);
        setLoading(true);

        const formattedValues = { ...values }; // Falls noch zusätzliche Formatierungen notwendig sind
        console.log("formattedValues:", formattedValues);

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: endpoint,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(formattedValues)
        };

        axios(config)
            .then((response) => {
                console.log("Erfolgreich gesendet:", response.data);
                setSended(true);
            })
            .catch((error) => {
                console.error("Fehler beim Senden:", error);
                const msg = error?.response?.status === 429
                    ? "Entschuldigen Sie bitte, aktuell gibt es zu viele Anfragen. Bitte kontaktieren Sie uns per Email."
                    : "Bitte prüfen Sie Ihre Eingaben und befüllen Sie alle Pflichtfelder.";
                setError(msg);

            })
            .finally(() => {
                setLoading(false);
            });
    }

    if (sended) {
        return (
            <div>
                <h2>Vielen Dank für Ihre Nachricht!</h2>
                <p>Wir werden uns schnellstmöglich bei Ihnen melden.</p>
            </div>
        );
    }

    let companyText = values.organization ? ` (${values.organization}) ` : "";
    let mailToUrl = "mailto:info@k4k-eg.de" + "?subject=Kontaktanfrage" + companyText + "&body=" + values.message


    return (
        <div className="ContactFormular">
            <h2>Hinterlassen Sie uns gerne eine Nachricht:</h2>
            {fields.map((field, index) => (
                <ContactFormularInput
                    key={index}
                    data={field}
                    onChange={(value) => handleChange(field.key, value)}
                />
            ))}
            {error && <div>
                <p><b>{error || "Unbekannter Fehler."} <a href={mailToUrl} target="_blank">Nachricht stattdessen per Mail senden <i className="fas fa-chevron-right"></i></a></b></p>

            </div>
            }
            <p>
                <small>
                    Durch das Absenden stimmen Sie unserer <Link to="/datenschutz">Datenschutzerklärung</Link> zu.
                </small>
            </p>
            <button onClick={handleSend} disabled={loading}>
                {loading ? "Senden..." : "Absenden"}
            </button>
        </div>
    );
}

export default ContactFormular;

function ContactFormularInput({ data, onChange = () => { } }) {
    const { title, isRequired, autoComplete, key, type } = data;
    const requiredLabel = isRequired && <span className="requiredLabel" title="Pflichtfeld">*</span>;

    function handleChange(e) {
        const value = e.target.value;
        onChange(value);
    }

    const props = {
        onChange: handleChange,
        autoComplete: autoComplete,
        type: type || "text",
        pattern: data.pattern,
    };

    return (
        <div className="ContactFormularInput">
            <h3>{title}{requiredLabel}</h3>
            <input {...props} maxLength={500} />
        </div>
    );
}
