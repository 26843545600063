import React from "react"
import MiniBar from "../../../atoms/MiniBar/MiniBar"
import "./ProjectList.scss"

function ProjectList({ title, projects = [] }) {
    return (
        <div className="ProjectList pt-5 pb-5">
            <div className="container">

                {/* <div className="row"> */}
                <div className=" p-0 mt-5 mb-5">
                    <h2>{title}</h2>
                    <MiniBar />
                </div>
                {/* </div> */}

                <div className="row mb-5 pb-5">
                    {projects.map((i, k) => <ProjectListItem {...i} key={k} />)}
                </div>
            </div>
        </div>
    )
}
export default ProjectList

function ProjectListItem({ title, sub, subTitle, image, icon, url }) {
    return (
        <a className="col-12 col-lg-6 pe-lg-0" href={url} target="_blank">
            <div className={"ProjectListItem  " + (sub && "sub")}>

                {icon ?
                    <i className={`fa-solid fa-${icon}`} />
                    :
                    <img src={image} />
                }
                <div>
                    <h3>{title}</h3>
                    {subTitle && <p className="mb-0">{subTitle}</p>}
                </div>
            </div>
        </a>
    )
}