import React from "react"
import TextPage from "../../components/templates/TextPage/TextPage"
import "./Page404.scss"

function Page404({ }) {
    return (
        <TextPage title="Seite nicht verfügbar." className="Page404">
            <p>Entweder funktioniert der von Ihnen angeklickte Link nicht oder die Seite wurde entfernt.</p>
            <span>Fehlercode: 404/403</span>
        </TextPage>
    )
}
export default Page404