import React from "react"
import IconBox from "../../components/atoms/images/IconBox/IconBox"
import Logo from "../../components/atoms/images/Logo/Logo"
import ContactFormular from "../../components/molecules/ContactFormular/ContactFormular"
import EasyBox from "../../components/molecules/boxes/EasyBox/EasyBox"
import EasyBoxFour from "../../components/molecules/boxes/EasyBoxFour/EasyBoxFour"
import ProjectList from "../../components/molecules/boxes/ProjectList/ProjectList"
import TeamBox from "../../components/molecules/boxes/TeamBox/TeamBox"
import "./ContactPage.scss"

function ContactPage({ }) {
    return (
        <div className="ContactPage" noContainer>

            {/* <VideoHeader video={headerVideo} slogan={"Smarte Kommunen \ngemeinsam gestalten."} /> */}


            <EasyBox
                odd
                image="/assets/image/company/LaptopK4K_2.png"
                title="Individuelles Strategiegespräch?"
                subTitle={`Sie möchten mehr über Ihre individuellen Vorteile für Ihre Organisation erfahren? Gerne stehen wir Ihnen für ein kostenfreies Strategiegespräch zur Verfügung.\n\n
                In nur wenigen Schritten können Sie von den Vorteilen und Angeboten der K4K eG profitieren und in die interkommunale Zusammenarbeit der Zukunft starten:`}
                items={[
                    {
                        title: "1. Erst- und Strategiegespräch",
                        icon: "calendar-check",
                    },
                    {
                        title: "2. Formeller Beitrittsprozess",
                        icon: "handshake",
                    },
                    {
                        title: "3. Gemeinsam durchstarten",
                        icon: "rocket",
                    },
                ]}
            />


            <TeamBox />


            <div className="container pb-5 mb-5">
                <div className="row align-items-start">
                    <div className="col-12 col-md-4 ">
                        <div className="sub pt-4 p-4 me-lg-4">

                            <div className="mb-4">
                                <Logo old />
                            </div>

                            <div className="mb-4">
                                <IconBox icon="mailbox" />
                                <h3>Anschrift:</h3>
                                <p>Laufdorfer Weg 4<br />35578 Wetzlar</p>
                            </div>

                            <div className="mb-4">
                                <IconBox icon="phone" />
                                <h3>Telefon:</h3>
                                <a href="tel:496441928820">(+49) 6441 / 92 88-20</a>
                            </div>

                            <div className="mb-4">
                                <IconBox icon="envelope" />
                                <h3>E-Mail:</h3>
                                <a target="_blank" href="mailto:info@k4k-eg.de">info@k4k-eg.de</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div>
                            <ContactFormular fields={fields} />
                        </div>
                    </div>
                </div>
            </div>

            <ProjectList title="Unsere Mitglieder" projects={projects} />

            <EasyBoxFour
                colored
                image="/assets/image/company/TabletMitVorteilenFürGemeinden.png"
                title="Von und für Kommunen"
                subTitle="Die K4K eG ist zu 100 Prozent kommunal und eine mitgliedergeführte, solidarische Organisation. Deutschlandweit, für die Zukunft der kommunalen Familie."
                items={[
                    {
                        title: "Landkreise",
                        icon: "map-location",
                    },
                    {
                        title: "Städte und Gemeinden",
                        icon: "building-columns",
                    },
                    {
                        title: "Kommunale Jobcenter",
                        icon: "briefcase",
                    },
                    {
                        title: "Kommunale Unternehmen",
                        icon: "building",
                    },
                ]}
            />

        </div>
    )
}
export default ContactPage



const fields = [
    {
        title: "Organisation",
        isRequired: false,
        key: "organization",
        autoComplete: "organization",
        type: "string",
        maxLength: 50,
        pattern: "^[\\w\\s]{0,50}$" // max 50 characters, letters, digits, underscores, spaces
    },
    {
        title: "Vor und Nachname",
        isRequired: true,
        key: "name",
        autoComplete: "name",
        type: "string",
        maxLength: 100,
        pattern: "^[\\p{L}\\s'-]{1,100}$" // max 100 characters, letters, spaces, hyphens, apostrophes
    },
    {
        title: "E-Mail",
        isRequired: true,
        key: "email",
        autoComplete: "email",
        type: "mail",
        pattern: "^[\\w.-]+@[\\w.-]+\\.[a-zA-Z]{2,6}$" // standard email pattern
    },
    {
        title: "Telefon",
        isRequired: false,
        key: "phone",
        type: "phone",
        pattern: "^[0-9\\+\\-\\(\\)\\s]{0,20}$" // max 20 characters, digits, +, -, (, ), spaces
    },
    {
        title: "Ihre Nachricht",
        isRequired: true,
        key: "message",
        type: "text",
        maxLength: 500,
        pattern: "^[\\s\\S]{1,500}$" // max 500 characters, any characters
    },
]



let imageUriPrefix = "/assets/image/kommunen/"
let projects = [
    {
        title: "Landkreis Marburg-Biedenkopf",
        url: "https://www.marburg-biedenkopf.de/",
        icon: "map-marker",
    },
    {
        title: "Landkreis Fulda",
        url: "https://www.landkreis-fulda.de/",
        icon: "map-marker",
    },
    {
        title: "Hochtaunuskreis",
        url: "https://www.hochtaunuskreis.de",
        icon: "map-marker",
    },



    {
        title: "Stadt Offenbach am Main",
        // image: imageUriPrefix + "offenbach.svg",
        url: "https://www.offenbach.de/",
        // subTitle: "Bevölkerung ca. 128.744 ",
        icon: "building-columns",
    },
    {
        title: "Stadt Wetzlar",
        // image: imageUriPrefix + "wetzlar.svg",
        url: "https://www.wetzlar.de/",
        icon: "building-columns",

    },
    {
        title: "Stadt Bad Vilbel",
        // image: imageUriPrefix + "bad_vilbel.svg",
        url: "https://www.bad-vilbel.de/",
        icon: "building-columns",
    },
    {
        title: "Stadt Friedberg",
        image: imageUriPrefix + "friedberg.svg",
        url: "https://www.friedberg-hessen.de/",
        icon: "building-columns",
    },
    {
        title: "Kommunales Jobcenter Lahn-Dill",
        image: imageUriPrefix + "jobcenter_lahn-dill.png",
        url: "https://www.jobcenter-lahn-dill.de/",
        icon: "briefcase",

    },
    {
        title: "Kommunales Jobcenter Groß-Gerau",
        image: imageUriPrefix + "jobcenter_gross-gerau.png",
        url: "https://jobcenter-gg.de/",
        icon: "briefcase",

    },
    {
        title: "Kommunales Center für Arbeit MKK",
        // image: imageUriPrefix + "jobcenter_gross-gerau.png",
        url: "https://www.kca-mkk.de",
        icon: "briefcase",
    },
    {
        title: "MainArbeit. Kommunales Jobcenter Offenbach",
        // image: imageUriPrefix + "jobcenter_gross-gerau.png",
        url: "https://www.mainarbeit-offenbach.de",
        icon: "briefcase",

    },
    {
        title: "Über 10 weitere Mitglieder",
        subTitle: "*mit Letter of Intent",
        icon: "chart-line-up",
        sub: true,
    },
]