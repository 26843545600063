import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Logo from "../../atoms/images/Logo/Logo";
import "./Header.scss";

function Header({ }) {



    let showContact = true




    return (
        <header className="Header">
            <div className="bar">
                <div className="container">
                    <div className="inner">
                        {/* <div className=" "> */}




                        {/* Logo */}
                        <Link to="/" className="">
                            <Logo />
                        </Link>



                        {/* Kontakt Button */}

                        <Routes>
                            <Route
                                path="/kontakt"
                                Component={React.Fragment}
                            />

                            <Route
                                path="*"
                                Component={ContactButton}
                            />
                        </Routes>

                        {/* </div> */}
                    </div>
                </div>
            </div>
            <div className="push" />
        </header>
    )
}
export default Header


let ContactButton = () => <Link to="/kontakt" className="">
    <button>
        Kontakt aufnehmen
        <i className="fa-solid fa-chevron-right" />
    </button>
</Link>

const navigation = [
    {
        title: "Startseite",
        link: "/",
        icon: <i className="fa-solid fa-home"></i>,
    },
    {
        title: "Kontakt",
        link: "/kontakt",
        icon: <i className="fa-solid fa-envelope"></i>,
    },
    // {
    //     icon: <i className="fa-solid fa-shopping-bag"></i>,
    //     title: "Shop",
    //     link: "https://shop.reformleben.de",
    // },
];