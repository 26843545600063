import React from "react"
import "./ImageBox.scss"

function ImageBox({ uri, title }) {
    if (!uri) return <p>ImageBox Fehler: Keine Bild-URI</p>

    let imageUrl = process.env.PUBLIC_URL + "/assets/image" + uri


    return (
        <div className="ImageBox pb-4" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="container">
                <div className="">
                    <h2>{title}</h2>
                    {/* <MiniBar /> */}
                </div>
            </div>
        </div>
    )
}
export default ImageBox