import React from "react"
import MiniBar from "../../../atoms/MiniBar/MiniBar"
import IconBox from "../../../atoms/images/IconBox/IconBox"
import "./EasyBox.scss"

let fallbackItems = [
    {
        title: "Effizienzsteigung",
        desc: "Die K4K ermöglicht Gemeinden die Bündelung von Ressourcen, um gemeinsam Innovations- und Effizienzpotenziale durch Digitalisierung auszuschöpfen.",
        icon: "chart-line-up"
    },
    {
        title: "Finanzielle Entlastung",
        desc: "In Zeiten finanzieller Herausforderungen bietet die K4K eine kosteneffiziente Lösung, um notwendige Ressourcen für die Digitalisierung bereitzustellen.",
        icon: "badge-percent"
    },
    {
        title: "Expertennetzwerk & Maßgeschneiderte Lösungen",
        desc: "Durch die Zusammenarbeit mit erfahrenen Experten profitieren K4K-Mitglieder von einem fundierten Wissenspool, der die Anforderungen jeder einzelnen Kommune optimal berücksichtigt.",
        icon: "users-line"
    },
]

function EasyBox({
    title = "Gemeinsam stark in der digitalen Revolution",
    subTitle = "Die K4K eG ermöglicht Kommunen den Schritt in eine vernetzte Zukunft. Entdecken Sie die Vorteile unserer Genossenschaft!",
    items = fallbackItems,
    odd = false,
    colored = false,
    image = "",
    imageAlt = "Symbolbild",
    prefix = <></>,
    suffix = <></>,
}) {
    return (
        <div className={"EasyBox pt-5 pb-5 mt-3 " + (odd && " odd ") + (colored && " colored ")}>
            <div className="container pt-5 pb-5">
                <div className={"row align-items-center"}>
                    <div className="col-12 col-lg-8 pt-4">
                        {prefix}
                        <h2>{title}</h2>
                        <MiniBar />
                        <p>{subTitle}</p>
                        <div className="d-flex flex-column flex-lg-row pt-4 pb-4">
                            {items.map((i, k) => <EasyBoxItem key={k} {...i} />
                            )}
                        </div>
                        <div className="d-flex flex-row">
                            {suffix}
                        </div>
                    </div>
                    <div className={"col-12 col-lg-4 "}>
                        <div className="imgBox">
                            <img className="w-100" src={image} alt={imageAlt} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EasyBox

function EasyBoxItem({ title, desc, icon = "frog" }) {

    return <div className="EasyBoxItem me-5 col-12 col-lg mb-3 mb-lg-0">
        <IconBox icon={icon} />
        <div>
            <h3>{title}</h3>
            <p>{desc}</p>
        </div>
    </div>
}