import React from "react"
import PowerdBy from "../../components/atoms/PowerdBy/PowerdBy"
import TextPage from "../../components/templates/TextPage/TextPage"
import "./ImpressumPage.scss"

function ImpressumPage({ }) {
    return (
        <TextPage title="Impressum" className="ImpressumPage">

            <h2>Angaben gemäß § 5 TMG</h2>

            <h3>Firmenname</h3>
            <p>K4K Kompetenz für Kommunale Innovation und Digitalisierung</p>

            <h3>Rechtsformzusatz</h3>
            <p>e.G.</p>

            <h3>Auflistung Vorstandsmitglieder/Vertretungsberechtigten</h3>
            <p>Maximilian Großmann</p>

            <h3>Sitz der Gesellschaft</h3>
            <p>Laufdorfer Weg 4, 35578 Wetzlar</p>

            <h3>Kontaktinformationen (e-mail/tel/fax/elekt. Kontaktformular)</h3>
            <p>Telefon:  + 49 (0) 64 41 / 92 88-20
                Email: info@k4k-eg.de

            </p>

            <h3>Registergericht</h3>
            <p>Amtsgericht Wetzlar<br />
                Wertherstraße 1 / Gebäude B<br />
                35578 Wetzlar<br /></p>


            <h3>Nr. Genossenschaftsregister</h3>
            <p>GnR 329</p>

            <h3>Zuständige Aufsichtsbehörde</h3>
            <p>Genossenschaftlicher Prüfungsverband e.V.<br />
                Straße des Friedens 4<br />
                99094 Erfurt
            </p>

            <hr />

            <h3>Technische Umsetzung:</h3>
            <PowerdBy />

        </TextPage>
    )
}
export default ImpressumPage